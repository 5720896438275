// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
  
    clientName: 'simoniz',
    apiGatewayFront: 'https://recaudos.mitiendasimonizco.com/',
    apiGatewayBackOffice: 'https://recaudos.mitiendasimonizco.com/',
    apiUrl: 'https://recaudos.mitiendasimonizco.com/',
    urlPath: 'https://recaudos.mitiendasimonizco.com/',
    customCss: false,
    applicationType: "agr",
    // Whether or not to enable debug mode
    // Setting this to false will disable console output
    enableDebug: false,
    // siteKeyCaptcha: '6LdLtBsgAAAAAJksLT4W-TI-oRed7Uk9B8Y-EECB', DEMO
    // siteKeyCaptcha: '6LfL5fkfAAAAALmrMTjEg18wnQm5uR3ZNbBy-pL7' ZX
  
    siteKeyCaptcha: '6LfyVN8kAAAAAOt93gnlRskMq0DVIrs-J0m2mHl-',
  
    googleAnalyticsCode: 'UA-140785247-1',
  };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.-
